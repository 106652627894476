import React from 'react';

const PersonalBlurb: React.FC = () => {
  return (
    <div className="bg-terminal-background text-terminal-text p-4 mt-8 mb-8 rounded border-terminal-subtle">
      <h2 className="text-xl font-bold mb-2 text-center p-3">A Little More About Me</h2>
      <p>
        As a passionate Software Engineer with a focus on backend systems and cloud technologies, I love solving complex problems and building scalable solutions. When not coding, I'm likely travelling, eating good food, or chilling at home with the kitties.
      </p>
      <p className="mt-2">
        Interested in collaborating? Feel free to reach out through any of the social links beneath Tux or send me an email at <a href="mailto:a.touche03@gmail.com" className="text-green-400 hover:text-green-500">a.touche03@gmail.com</a>.
      </p>
    </div>
  );
};

export default PersonalBlurb;

