import React from 'react';
import AboutMe from 'components/home/AboutMe';
import PersonalBlurb from 'components/home/Blurb';
import Experience from 'components/home/Experience';
import Skills from 'components/home/Skills';

const HomePage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <AboutMe />
      <Skills />
      <PersonalBlurb />
      <Experience />
    </div>
  );
};

export default HomePage;
