import React from 'react';

import { jobData } from 'utils/data/personalData';

const JobExperience: React.FC = () => {
  return (
    <div className="bg-terminal-background text-terminal-text w-full">
      {jobData.map((job, index) => (
        <div key={index} className="p-4 border-b border-terminal-subtle last:border-b-0 w-full">
          <div className="flex flex-col sm:flex-row items-center space-x-0 sm:space-x-3">
            <img src={job.icon} alt="Job Icon" className="h-10 w-10 rounded mb-2 sm:mb-0" />
            <div className="flex-1">
              <h3 className="text-lg font-bold">{job.title}</h3>
              <p className="text-sm text-gray-400">{job.company} (Remote)</p>
              <p className="text-sm">{job.period}</p>
            </div>
          </div>
          <ul className="list-disc pl-5 sm:pl-14 mt-2">
            {job.details.map((detail, idx) => (
              <li key={idx} className="text-sm mt-1">{detail}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default JobExperience;
