import portlandStateIcon from 'assets/img/portland_state_university_logo.png';
import ctranIcon from 'assets/img/c_tran_logo.png';


const contactData = {
    linked_in: "https://www.linkedin.com/in/actiii03/",
}

const jobData = [
  {
    title: 'Integration Analyst Programmer',
    company: 'Portland State University - Fulltime',
    period: 'Jan 2023 - Present',
    details: [
      'Principal Developer for our Integration AWS framework -- Developed REST API deployment process for Enterprise Integrations.',
      'Provided development support for legacy Enterprise Resource Planning software system (Banner).',
    ],
    icon: portlandStateIcon
  },
  {
    title: 'Computer Science Tutor & Coordinator',
    company: 'Portland State University - Part-time',
    period: 'Sep 2021 - Dec 2022',
    details: [
      'Led a team of nine tutors who interacted with 180+ undergraduates, conducted interviews, and scheduled and coordinated team activities.',
      'Assisted 80+ undergrads with data structure, algorithm design, and other undergraduate course to aid them in successfully understanding core concepts.',
    ],
    icon: portlandStateIcon
  },
  {
    title: 'Business Data Analyst',
    company: 'Portland State University - Internship',
    period: 'Nov 2019 - Mar 2020',
    details: [
      'Optimized data warehouse by checking data type and range of values, documenting design, and mapping relationships for statistical analysis, improving data governance.',
      'Designed Key-Performance Indicating dashboards in Microsoft Power BI and made them accessible through Sharepoint intranet for informed business decisions making.',
    ],
    icon: ctranIcon
  }
];



export {contactData, jobData}
