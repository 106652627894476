import React from 'react';

const WelcomeMessage: React.FC = () => {
  return (
    <div className="basis-full sm:w-full md:basis-3/5 text-terminal-text bg-terminal-background p-4 border-terminal-subtle rounded-lg">
      <h1 className="font-mono text-lg sm:text-xl md:text-2xl">
        <span className="text-green-400">Hi <span className="wave">👋</span>, I'm</span> Armant Touche!
      </h1>
      <ul className="font-mono list-disc pl-5 mt-2 text-sm sm:text-base">
        <li>Backend Software Engineer by trade</li>
        <li>Skilled in Python, SQL, Terraform, and scripting. It depends! 🙂</li>
        <li>Experienced in Integrations and Cloud Engineering with a focus on DevOps</li>
      </ul>
    </div>
  );
};

export default WelcomeMessage;

