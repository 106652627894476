import React from 'react';
import JobExperience from './JobExperience';

const Experience: React.FC = () => {
  return (
    <div className="w-full p-4 md:p-8">
      <h2 className="text-lg md:text-xl font-bold text-center mb-6">Experience</h2>
      <JobExperience />
    </div>
  );
};

export default Experience;
