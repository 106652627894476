import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import AboutMePage from './pages/AboutMePage';

const App: React.FC = () => {
    return (
        <Router>
            <div className="min-h-screen bg-terminal-background text-terminal-text">
                <Navbar />
                <div className="container mx-auto p-20">
                    <Routes>
                        <Route path="/" element={<AboutMePage />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
};

export default App;

