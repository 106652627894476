import React from 'react';
import terraformIcon from 'assets/img/terraform-icon.png';
import pythonIcon from 'assets/img/python-icon.png';
import reactIcon from 'assets/img/react-icon.png';
import awsIcon from 'assets/img/aws-icon.png';
import gcpIcon from 'assets/img/gcp-icon.png';

const skillsData = [
  { src: terraformIcon, label: 'Terraform' },
  { src: pythonIcon, label: 'Python' },
  { src: reactIcon, label: 'React' },
  { src: awsIcon, label: 'AWS' },
  { src: gcpIcon, label: 'GCP' },
];

const Skills: React.FC = () => {
  return (
    <div className="p-8 bg-terminal-background text-terminal-text">
      <h2 className="text-xl text-center font-bold mb-4">Techie Skills</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {skillsData.map((skill, index) => (
          <div key={index} className="flex flex-col items-center text-center p-5 border border-terminal-subtle rounded-lg">
            <img src={skill.src} alt={skill.label} className="h-14 w-18 mb-2" />
            <span>{skill.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;

