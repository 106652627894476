import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="w-full bg-terminal-background text-terminal-text text-center p-4 border-t border-terminal-subtle">
      <p>© {new Date().getFullYear()} Armant Touche. All rights reserved.</p>
      <p>Designed and Developed by Armant Touche</p>
    </footer>
  );
};

export default Footer;
