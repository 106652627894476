import React from 'react';
import { createRoot } from 'react-dom/client'; // Correctly import createRoot
import './assets/styles/index.css'; // Tailwind CSS import
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!); // TypeScript may require a non-null assertion

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
